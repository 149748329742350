$('#verification_code').keyup(function(){
    if(!$(this).val()) {
      $(this).parent().parent().find('.new-modal-button').removeClass('active').addClass('inactive')
    } else {
      $(this).parent().parent().find('.new-modal-button').addClass('active').removeClass('inactive')
    }
})
function resendEmail() {
  actionWebApiRestrict('/v1/consumer/verification_email/resend', {}, 'POST').done(function(json) {
    if(json.status == 600) {
      $('#verify-email-message').html('Kode berhasil dikirim').fadeIn('slow', function(){
        setTimeout(function(){ $('#verify-email-message').fadeOut(5000) }, 1000);
      })
    } else {
      $('#verify-email-message').html('Kode gagal dikirim').fadeIn('slow', function(){
        setTimeout(function(){ $('#verify-email-message').fadeOut(5000) }, 1000);
      })
    }
  });
}
function verifyEmail() {
  if($('#verify-email-inner').find('.new-modal-button').hasClass('active')) {
    var param = {
      verification_code: $('#verification_code').val()
    }
    actionWebApiRestrict('/v1/consumer/verification_email/check', param, 'POST').done(function(json) {
      if(json.status == 600) {
        isAccountVerified = true;
        $('#overlay-verify-email').addClass('collapse')
        $('#verification-bar').parent().addClass('hide').removeClass('show')
        if(completenessPercentage != 100){
          $('#completeness-bar').parent().addClass('show').removeClass('hide')
        }
        document.location.reload(true)
      } else {
        $('#overlay-verify-email').addClass('collapse')
        showFailureMessage('Verifikasi e-mail Anda gagal')
      }
    });
  }
}

$('#overlay-verify-email').click(function(event) {
    if (event.target === this) {
        $('#overlay-verify-email').addClass('collapse');
    }
});
$('#verify-email .close-modal').click(function(event) {
    $('#overlay-verify-email').addClass('collapse');
});
